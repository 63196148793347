/* eslint-disable */

const TOLERANCE = 0.0001;

const isEqual = (a, b) => {
    return Math.abs(a - b) < TOLERANCE;
};

const roundToDecimals = (num, places) => {
    if (num > 0 && num < TOLERANCE) return 0;
    return +`${Math.round(`${num}e+${places}`)}e-${places}`;
};

const itemActiveStatusMap = {
    Ansøgt: 11,
    'P: Aktiv': 1,
    'sæt til videresalg': 9,
    'sæt til videresalg (AFVENT)': 47,
    'sæt til videresalg - kundevalgt': 48,
    Indleveret: 24,
    Guldgenstand: undefined,
    'sæt til videresalg': undefined,
    'Sæt til Ekstern Videresalg': undefined,
    'Ekstern Videresalg': undefined,
    'Ekstern Videresalg - Chrono.dk': undefined,
    'Ekstern Videresalg - Time to watch': undefined,
    'Send til smelt': undefined,
    'Send til smelt (AL)': undefined,
    Solgt: undefined,
    'Solgt - layaway igang': undefined,
    'Under videresalg i shopify': undefined,
    'Under videresalg - reserveret': undefined,
    'Solgt - layaway færdig': undefined,
    'Solgt - Udland (uden for eu)': undefined,
    'Solgt - Udland (inden for eu)': undefined,
    'Under forsendelse til kbh': undefined,
    'Modtaget i kbh': undefined,
};

const formatDKK = (val, style = 'currency') => {
    if (!style) {
        style = 'decimal';
    }
    return new Intl.NumberFormat('da', {
        style,
        currency: 'DKK',
    }).format(val);
};

const getDataFromForm = (identifier) => {
    return $(identifier)
        .serializeArray()
        .reduce(function (obj, item) {
            if (item.value) {
                // eslint-disable-next-line no-param-reassign
                obj[item.name] = item.value;
            }
            return obj;
        }, {});
};

const highlight = (el, highlightColor, textColor, duration) => {
    let tmpEl = el;
    if (!(el instanceof jQuery)) {
        tmpEl = $(el);
    }
    const locked = tmpEl.attr('highlightLocked');
    const highlightBg = highlightColor || '#FFFF9C';
    const hightlightTextColor = textColor || '#000';
    const animateMs = duration || 1500;
    const originalBg = tmpEl.css('backgroundColor');
    const originalTextColor = tmpEl.css('color');
    if (!locked) {
        tmpEl.attr('highlightLocked', true);
        tmpEl.stop().css({ 'background-color': highlightBg, color: hightlightTextColor });
        setTimeout(function () {
            tmpEl.attr('highlightLocked', null);
            tmpEl.stop().css({ 'background-color': originalBg, color: originalTextColor });
        }, animateMs);
    }
};

function throttle(f, delay) {
    var timer = null;
    return function () {
        var context = this,
            args = arguments;
        clearTimeout(timer);
        timer = window.setTimeout(function () {
            f.apply(context, args);
        }, delay || 500);
    };
}

const textIsNumbersInputListener = (querySelector) => {
    $(querySelector).on('input', function () {
        var inputValue = $(this).val();
        var sanitizedValue = inputValue.replace(/,/g, '.').replace(/[^0-9,.]/g, ''); // Replace any characters that are not numbers, commas, or dots

        if (sanitizedValue !== inputValue) {
            $(this).val(sanitizedValue);
        }

        if (isNaN(sanitizedValue)) {
            $(this).val(sanitizedValue.slice(0, -1)); // Remove the last character
        }
    });
};

const groupBy = (arr, keyOrFunction) => {
    return arr.reduce(function (acc, item) {
        const key = typeof keyOrFunction === 'function' ? keyOrFunction(item) : item[keyOrFunction];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }, {});
};

const averageArrayOfNumbers = (arr) => {
    const out = [];
    let sum = Math.round(arr.reduce((acc, i) => acc + i, 0) * 100) / 100;
    if (sum === 0) {
        arr = arr.map(() => 1);
        sum = arr.length;
    }
    for (let i = 0; i < arr.length; i += 1) {
        if (i === arr.length - 1) {
            out[i] = 1 - out.reduce((acc, n) => acc + n, 0);
        } else {
            out[i] = arr[i] / sum;
        }
    }

    return out;
};

const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            callback.apply(null, args);
        }, wait);
    };
};

const toggleLoadingState = (isLoading) => {
    $('#bigspinner').toggleClass('d-none', !isLoading);
    $('button').prop('disabled', isLoading);
    $("span[role='status']").toggleClass('d-none', !isLoading);
};

// $(document).ready(() => {
//     // This will run after the document is ready
//     $.ajaxSetup({
//         beforeSend: function () {
//             // This will run before each AJAX request is sent
//             $('button').attr('disabled', true);
//             $("span[role='status']").toggleClass('d-none');
//         },
//         complete: function () {
//             // This will run after each AJAX request is complete, regardless of success or error
//             $('button').attr('disabled', false);
//             $("span[role='status']").toggleClass('d-none');
//         },
//     });
// });

const waitPopup = (text, waitTime = 5000, cancelText = 'Cancel', continueText = 'Continue') => {
    return new Promise((resolve) => {
        // Create and inject CSS if not already present
        if (!document.getElementById('wait-popup-styles')) {
            const styles = `
                .wait-popup-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.5);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 9999;
                }
                .wait-popup {
                    background: white;
                    padding: 20px;
                    border-radius: 8px;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                    width: 90%;
                    max-width: 400px;
                    text-align: center;
                }
                .wait-popup-progress {
                    width: 100%;
                    height: 4px;
                    background: #eee;
                    margin: 15px 0;
                    border-radius: 2px;
                    overflow: hidden;
                }
                .wait-popup-progress-bar {
                    width: 0%;
                    height: 100%;
                    background: #2196F3;
                    transition: width linear;
                }
                .wait-popup-buttons {
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    margin-top: 15px;
                }
                .wait-popup-button {
                    padding: 8px 16px;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    transition: opacity 0.2s;
                }
                .wait-popup-button:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
                .wait-popup-button.primary {
                    background: #2196F3;
                    color: white;
                }
                .wait-popup-button.secondary {
                    background: #9e9e9e;
                    color: white;
                }`;

            const styleSheet = document.createElement('style');
            styleSheet.id = 'wait-popup-styles';
            styleSheet.textContent = styles;
            document.head.appendChild(styleSheet);
        }

        // Create modal HTML
        const modal = document.createElement('div');
        modal.className = 'wait-popup-overlay';
        modal.innerHTML = `
            <div class="wait-popup">
                <p>${text}</p>
                <div class="wait-popup-progress">
                    <div class="wait-popup-progress-bar"></div>
                </div>
                <div class="wait-popup-buttons">
                    <button class="wait-popup-button secondary" id="cancelBtn" disabled>${cancelText}</button>
                    <button class="wait-popup-button primary" id="continueBtn" disabled>${continueText}</button>
                </div>
            </div>`;

        // Add modal to body
        document.body.appendChild(modal);

        // Get elements
        const progressBar = modal.querySelector('.wait-popup-progress-bar');
        const cancelBtn = modal.querySelector('#cancelBtn');
        const continueBtn = modal.querySelector('#continueBtn');

        // Setup button handlers
        cancelBtn.addEventListener('click', () => {
            modal.remove();
            resolve(false);
        });

        continueBtn.addEventListener('click', () => {
            modal.remove();
            resolve(true);
        });

        // Start progress bar animation
        progressBar.style.transition = `width ${waitTime}ms linear`;
        // Force reflow to ensure transition starts
        progressBar.offsetHeight;
        progressBar.style.width = '100%';

        // Enable buttons after wait time
        setTimeout(() => {
            cancelBtn.disabled = false;
            continueBtn.disabled = false;
        }, waitTime);
    });
};
